export default defineNuxtPlugin(() => {
    return {
        provide: {
            environment: () => {
                const env = process.env.NODE_ENV;

                if (env === "development") {
                    return "development";
                } else if (env === "staging") {
                    return "staging";
                }

                return "production";
            },
        },
    };
});
