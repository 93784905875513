import mitt from "mitt";

/**
 * Global event emitter
 */
export default defineNuxtPlugin(() => {
    const emitter = mitt();

    return {
        provide: {
            event: emitter.emit,
            listen: emitter.on,
        },
    };
});
