import * as Sentry from "@sentry/vue";

export default defineNuxtRouteMiddleware(() => {
    const user = useSanctumUser<KadeweUser>();

    if (!user.value) {
        Sentry.setUser(null);
        return;
    }

    Sentry.setUser({
        id: user.value?.id,
    });
});
