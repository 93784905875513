import { install } from "vue3-recaptcha-v2";

export default defineNuxtPlugin((nuxtApp) => {
    const {
        public: {
            recaptcha: { site_key },
        },
    } = nuxtApp.$config;

    nuxtApp.vueApp.use(install, {
        sitekey: site_key,
        cnDomains: false,
    });
});
