import { default as _91slug_932cA5qLUJfmMeta } from "/build/pages/cms/[slug].vue?macro=true";
import { default as email_45confirmAmxbbmab2tMeta } from "/build/pages/email-confirm.vue?macro=true";
import { default as faqHyNfpnhhhXMeta } from "/build/pages/faq.vue?macro=true";
import { default as forgot_45password_45sentrTE8weCDzjMeta } from "/build/pages/forgot-password-sent.vue?macro=true";
import { default as forgot_45password7bXkSsE1kKMeta } from "/build/pages/forgot-password.vue?macro=true";
import { default as index2yY1hMMS9YMeta } from "/build/pages/index.vue?macro=true";
import { default as loginSPHB0sFF0AMeta } from "/build/pages/login.vue?macro=true";
import { default as accountn7uJSZtTrXMeta } from "/build/pages/portal/account.vue?macro=true";
import { default as cardJvHIiipYPAMeta } from "/build/pages/portal/card.vue?macro=true";
import { default as helpqOnNEzKT3tMeta } from "/build/pages/portal/help.vue?macro=true";
import { default as logoutl2BDFcXhgbMeta } from "/build/pages/portal/logout.vue?macro=true";
import { default as notificationsvrIEVLdavdMeta } from "/build/pages/portal/notifications.vue?macro=true";
import { default as rewardsgrYyNbFOvRMeta } from "/build/pages/portal/rewards.vue?macro=true";
import { default as statusUgWqa7d6OhMeta } from "/build/pages/portal/status.vue?macro=true";
import { default as register_45mergel1s3u6IqOCMeta } from "/build/pages/register-merge.vue?macro=true";
import { default as registerac5J73FHoCMeta } from "/build/pages/register.vue?macro=true";
import { default as reset_45password_45successQmdnMor5K0Meta } from "/build/pages/reset-password-success.vue?macro=true";
import { default as _91token_93IZY4DHmFoJMeta } from "/build/pages/reset-password/[email]/[token].vue?macro=true";
import { default as sentryvkPv6Ir9rcMeta } from "/build/pages/sentry.vue?macro=true";
import { default as two_45factor0cQ17sobXEMeta } from "/build/pages/two-factor.vue?macro=true";
import { default as verifiedz6AySEtoulMeta } from "/build/pages/verified.vue?macro=true";
import { default as verifykLacMrLwGAMeta } from "/build/pages/verify.vue?macro=true";
export default [
  {
    name: "cms-slug___de",
    path: "/cms/:slug()",
    meta: _91slug_932cA5qLUJfmMeta || {},
    component: () => import("/build/pages/cms/[slug].vue")
  },
  {
    name: "email-confirm___de",
    path: "/email-confirm",
    meta: email_45confirmAmxbbmab2tMeta || {},
    component: () => import("/build/pages/email-confirm.vue")
  },
  {
    name: "faq___de",
    path: "/faq",
    meta: faqHyNfpnhhhXMeta || {},
    component: () => import("/build/pages/faq.vue")
  },
  {
    name: "forgot-password-sent___de",
    path: "/forgot-password-sent",
    meta: forgot_45password_45sentrTE8weCDzjMeta || {},
    component: () => import("/build/pages/forgot-password-sent.vue")
  },
  {
    name: "forgot-password___de",
    path: "/forgot-password",
    meta: forgot_45password7bXkSsE1kKMeta || {},
    component: () => import("/build/pages/forgot-password.vue")
  },
  {
    name: "index___de",
    path: "/",
    meta: index2yY1hMMS9YMeta || {},
    component: () => import("/build/pages/index.vue")
  },
  {
    name: "login___de",
    path: "/login",
    meta: loginSPHB0sFF0AMeta || {},
    component: () => import("/build/pages/login.vue")
  },
  {
    name: "portal-account___de",
    path: "/portal/account",
    meta: accountn7uJSZtTrXMeta || {},
    component: () => import("/build/pages/portal/account.vue")
  },
  {
    name: "portal-card___de",
    path: "/portal/card",
    meta: cardJvHIiipYPAMeta || {},
    component: () => import("/build/pages/portal/card.vue")
  },
  {
    name: "portal-help___de",
    path: "/portal/help",
    meta: helpqOnNEzKT3tMeta || {},
    component: () => import("/build/pages/portal/help.vue")
  },
  {
    name: "portal-logout___de",
    path: "/portal/logout",
    meta: logoutl2BDFcXhgbMeta || {},
    component: () => import("/build/pages/portal/logout.vue")
  },
  {
    name: "portal-notifications___de",
    path: "/portal/notifications",
    meta: notificationsvrIEVLdavdMeta || {},
    component: () => import("/build/pages/portal/notifications.vue")
  },
  {
    name: "portal-rewards___de",
    path: "/portal/rewards",
    meta: rewardsgrYyNbFOvRMeta || {},
    component: () => import("/build/pages/portal/rewards.vue")
  },
  {
    name: "portal-status___de",
    path: "/portal/status",
    meta: statusUgWqa7d6OhMeta || {},
    component: () => import("/build/pages/portal/status.vue")
  },
  {
    name: "register-merge___de",
    path: "/register-merge",
    meta: register_45mergel1s3u6IqOCMeta || {},
    component: () => import("/build/pages/register-merge.vue")
  },
  {
    name: "register___de",
    path: "/register",
    meta: registerac5J73FHoCMeta || {},
    component: () => import("/build/pages/register.vue")
  },
  {
    name: "reset-password-success___de",
    path: "/reset-password-success",
    meta: reset_45password_45successQmdnMor5K0Meta || {},
    component: () => import("/build/pages/reset-password-success.vue")
  },
  {
    name: "reset-password-email-token___de",
    path: "/reset-password/:email()/:token()",
    meta: _91token_93IZY4DHmFoJMeta || {},
    component: () => import("/build/pages/reset-password/[email]/[token].vue")
  },
  {
    name: "sentry___de",
    path: "/sentry",
    component: () => import("/build/pages/sentry.vue")
  },
  {
    name: "two-factor___de",
    path: "/two-factor",
    meta: two_45factor0cQ17sobXEMeta || {},
    component: () => import("/build/pages/two-factor.vue")
  },
  {
    name: "verified___de",
    path: "/verified",
    meta: verifiedz6AySEtoulMeta || {},
    component: () => import("/build/pages/verified.vue")
  },
  {
    name: "verify___de",
    path: "/verify",
    meta: verifykLacMrLwGAMeta || {},
    component: () => import("/build/pages/verify.vue")
  }
]