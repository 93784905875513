<template>
    <component
        :is="componentToShow"
        :to="to"
        :class="buttonClasses"
        :type="button"
        :disabled="disabled"
        :aria-disabled="disabled"
    >
        <div
            v-if="loading && loadingEstimationMs > 0"
            class="block absolute h-1 -top-[5px] -left-[1px] right-0 bg-gray-300"
            style="width: calc(100% + 2px)"
        >
            <div
                class="h-1 bg-gray-50"
                :style="{ width: `${loadingProgressPercentage}%` }"
            />
        </div>

        <div class="flex justify-center">
            <span ref="buttonText">
                <slot />
            </span>

            <span
                v-if="loading"
                class="ml-4 inline-block w-4 h-4"
            >
                <loading-animation :size="20" />
            </span>
        </div>
    </component>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        element?: "button" | "link";
        type?: "default" | "primary" | "text";
        button?: "submit" | "reset" | "button";
        to?: string;
        disabled?: boolean;
        classes?: string[];
        loading?: boolean;
        loadingMessages?: string[];
        loadingEstimationMs?: number;
    }>(),
    {
        element: "button",
        type: "default",
        button: "button",
        to: "",
        disabled: false,
        classes: () => [],
        loading: false,
        loadingMessages: () => [],
        loadingEstimationMs: 0,
    },
);

const loadingAnimationInterval = ref<number | null>(null);
const loadingMessageIndex = ref(0);
const loadingMessageInterval = 2000;
const loadingProgress = ref(0);
const buttonText = ref<HTMLSpanElement | null>(null);
const initialButtonText = ref("");

const buttonClasses = computed(() => {
    const dynamicClasses = ["button", `button-${props.type}`];

    dynamicClasses.push(...props.classes);

    if (props.disabled) {
        dynamicClasses.push(`button-${props.type}-disabled`);
    }

    return dynamicClasses;
});

const componentToShow = computed(() => {
    if (props.element === "button") {
        return "button";
    }

    return resolveComponent("NuxtLink");
});

const setButtonText = function (newText: string) {
    if (buttonText.value) {
        buttonText.value.innerText = newText;
    }
};

const stopLoadingAnimation = function () {
    if (loadingAnimationInterval.value) {
        clearInterval(loadingAnimationInterval.value);
        loadingAnimationInterval.value = null;
        loadingProgress.value = props.loadingEstimationMs;
    }
};

const resetLoadingAnimation = function () {
    loadingProgress.value = 0;
    loadingMessageIndex.value = 0;
    setButtonText(initialButtonText.value);
};

const startLoadingAnimation = function () {
    if (props.loadingMessages.length === 0) {
        return;
    }

    if (!loadingAnimationInterval.value) {
        resetLoadingAnimation();
        loadingAnimationInterval.value = setInterval(() => {
            if (loadingMessageIndex.value + 1 > props.loadingMessages.length) {
                stopLoadingAnimation();
                return;
            }

            loadingMessageIndex.value += 1;
            setButtonText(props.loadingMessages[loadingMessageIndex.value - 1]);

            loadingProgress.value = loadingMessageInterval * loadingMessageIndex.value;
        }, loadingMessageInterval);
    }
};

const loadingProgressPercentage = computed(() => {
    return Math.min((loadingProgress.value / props.loadingEstimationMs) * 100, 100);
});

onMounted(() => {
    initialButtonText.value = buttonText.value?.innerText || "";
});

defineExpose({
    startLoadingAnimation,
    stopLoadingAnimation,
    resetLoadingAnimation,
});
</script>
